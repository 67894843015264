<template>
    <el-dialog title="个人信息授权书" :visible.sync="visible" width="60%" :before-close="close" :modal-append-to-body="false" center>
        <iframe style="width: 100%;height: 60vh;" :src="`./auth-file.html?fromName=潍坊良辉商贸有限公司&toName=淮滨县源盛网络科技有限公司&person=${name}&date=${getDate()}`"></iframe>
    </el-dialog>
</template>
<script>
export default {
    props:{
        name:{tpye:String,default:()=>''}
    },
    data() {
        return {
            visible: false
        }
    },
    methods: {
        open() {
            this.visible = true
        },
        close() {
            this.visible = false
        },
        getDate(){
            var date = new Date()
            return `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`
        }
    }
}
</script>
<style less="scope">
    .content-h{
        height: 100vh;
    }
</style>