import API from "@/api/index.js";

export default {
  // 直接查询
  directQuery: params => {
    return API.requestGet({ url: "direct/query", params });
  },
  // 查询列表
  getList: param => {
    return API.requestGet({
      url: "friend/getList",
      auth: true,
      params: { isDirectQuery: true, ...param }
    });
  },
  // 删除好友
  delFriend: data => {
      return API.requestPost({
        url: "friend/delFriend",
        auth: true,
        data
      });
  },
  // 查询列表
  detail: id => {
    return API.requestGet({
      url: "friend/detail",
      auth: true,
      params: { id }
    });
  },
  // 查询列表
  superInfo: param => {
    return API.requestPost({
      url: "user/superInfo",
      auth: true,
      param
    });
  }
};
