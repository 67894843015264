<template>
    <el-dialog title="注意" :visible.sync="visible" width="25%" :before-close="close" :modal-append-to-body="false">
        <el-row :gutter="22">
            <el-col :span="1">
                <el-checkbox v-model="agree"></el-checkbox>
            </el-col>
            <el-col :span="22">
                <span>同意授权即代表你已阅读<el-link :underline="false" type="primary" @click="$emit('preview')">《个人信息授权书》</el-link>并同意将用户信息授权给平台。</span>
            </el-col>
        </el-row>
        <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="visible = false">拒绝</el-button>
            <el-button type="primary" :disabled="!agree" size="small" @click="$emit('licenseQuery')">同意授权</el-button>
        </span>
    </el-dialog>
</template>

<script>
export default {
    props: {
        form: { type: Object, default: () => { } }
    },
    data() {
        return {
            visible: false,
            agree: true
        }
    },
    methods: {
        open() {
            this.visible = true
        },
        close() {
            this.visible = false
        }
    }
}
</script>